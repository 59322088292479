import React, { ComponentType } from 'react';
import useHasConsent from '@src/components/cmp/use-has-consent';

const withConsent = <T,>(WrappedComponent: ComponentType<T>, consentName: string) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithConsent = (props: T) => {
    const { hasConsent } = useHasConsent(consentName);
    if (!hasConsent) {
      return null;
    }
    return <WrappedComponent {...props} />;
  };

  ComponentWithConsent.displayName = displayName;

  return ComponentWithConsent;
};

export default withConsent;
