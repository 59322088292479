import { useCallback, useEffect, useState } from 'react';
import getCmp from '@src/utils/cmp';

const useHasConsent = (consentName: string) => {
  const [hasConsent, setHasConsent] = useState<boolean>(false);

  const updateConsent = useCallback(() => {
    getCmp()
      .then(
        cmp =>
          cmp
            .getConsents()
            .filter(c => c.name === consentName)
            .shift()?.consent?.status ?? false,
      )
      .then(status => setHasConsent(status));
  }, [consentName]);

  useEffect(() => {
    document.addEventListener('cmp:saved', updateConsent);
    document.addEventListener('cmp:confirmed', updateConsent);
    document.addEventListener('cmp:userActionNotRequired', updateConsent);
    document.addEventListener('cmp:failed', updateConsent);

    return () => {
      document.removeEventListener('cmp:saved', updateConsent);
      document.removeEventListener('cmp:confirmed', updateConsent);
      document.removeEventListener('cmp:userActionNotRequired', updateConsent);
      document.removeEventListener('cmp:failed', updateConsent);
    };
  }, [updateConsent]);

  useEffect(() => updateConsent(), [updateConsent]);

  return { hasConsent };
};

export default useHasConsent;
