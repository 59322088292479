import React, { useEffect } from 'react';
import { OutbrainWidget } from '@src/interfaces';
import { loadScript } from '@src/utils/scripts';
import { withAdsEnabled } from '@src/utils/somtag';
import withConsent from '@src/components/cmp/with-consent';
import { useRouter } from 'next/router';

// @see https://developer.outbrain.com/standard-js-detailed-implementation-guide/appendix-c-dynamic-widget-loading/
interface OBR {
  extern: {
    refreshWidget: (src: string) => void;
    reloadWidget: () => void;
    renderSpaWidgets: (src: string) => void;
    researchWidget: () => void;
  };
}

const Widget = ({ src, widgetId }: Pick<OutbrainWidget, 'src' | 'widgetId'>) => {
  const router = useRouter();
  useEffect(() => {
    if (router.asPath.includes('nooutbrain=1')) {
      return;
    }
    loadScript<OBR>('https://widgets.outbrain.com/outbrain.js', 'OBR')
      .then(obr => obr.extern.refreshWidget(src))
      .catch(reason => {
        console.log('Could not load outbrain.js', reason);
      });
  }, [src, router]);

  return (
    <div className="OB-REACT-WRAPPER">
      <div className="OUTBRAIN" data-src={src} data-widget-id={widgetId} />
    </div>
  );
};

export default withAdsEnabled(withConsent(Widget, 'Outbrain'));
